<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="活动任务模板">
          <template slot="extra">
            <a-button class="editable-add-btn" @click="handleAdd" size="small">
              新增
            </a-button>
          </template>
        </a-page-header>
      </div>
      <div ref="form">
        <a-form :form="form" layout="inline" @submit="handleSubmit" style="padding: 15px 0" ref="form">
          <a-form-item label="任务名称">
            <a-input style="width: 180px" v-decorator="['search_name', { rules: [{ required: false }] }]">
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table :columns="columns" :data-source="taskList" :pagination="false" :scroll="{ x: 1000, y: table_h }"
        :row-key="(record) => record.id" size="middle" ref="tableList">
        <img slot="img" slot-scope="img" :src="img" />
        <span slot="price" slot-scope="price">{{ price }}元</span>
        <span slot="oldPrice" slot-scope="oldPrice">{{ oldPrice }}元</span>
        <span slot="percent" slot-scope="percent">{{ percent }}%</span>
        <span slot="status" slot-scope="status">
          <a-icon type="check" v-if="status == 1" style="color: #0092dc" />
          <a-icon type="close" v-if="status == 0" style="color: #ed5565" />
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="handel(btn, num)" size="small"
            style="margin: 5px">
            {{ btn }}
          </a-button>
        </span>
      </a-table>
      <div ref="pagination">
        <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
          :show-total="(total) => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="onPageChange">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <a-modal v-model="visible" :title="modelTitle" width="700px" :confirm-loading="confirmLoading" @ok="handleOk"
        ok-text="确认" cancel-text="取消">
        <a-form :form="addform" @submit="handleSubmit" layout="horizontal">
          <a-form-item label="任务名称：">
            <a-input v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入任务名称!' }],
              },
            ]" />
          </a-form-item>
          <a-row class="form_title">看板内容</a-row>
          <a-form-item label="看板标题：">
            <a-input v-decorator="[
              'content.top_board.title',
              {
                rules: [{ required: true, message: '请输入看板标题!' }],
              },
            ]" />
          </a-form-item>
          <a-row>
            <a-col v-for="(item, index) in top_board" :key="index" class="top_board">
              <a-form-item label="标题：">
                <a-input v-decorator="[
                  'content.top_board.content[' + index + '].title',
                  {
                    rules: [{ required: true, message: '请输入标题!' }],
                  },
                ]" />
              </a-form-item>
              <a-form-item label="值：" style="margin-left:10px">
                <a-input v-decorator="[
                  'content.top_board.content[' + index + '].value',
                  {
                    rules: [{ required: true, message: '请输入值!' }],
                  },
                ]" />
              </a-form-item>

              <a-icon type="minus-circle" style="padding-left: 10px;margin-top:-20px;  font-size: 20px"
                v-if="(index == top_board.length - 1) && (index != 0)"
                @click="changeControl('delete', 'top_board', index)" />
              <a-icon type="plus-circle" style="padding-left: 10px;margin-top:-20px; font-size: 20px"
                v-if="(index == top_board.length - 1)" @click="changeControl('add', 'top_board', index)" />
            </a-col>
          </a-row>
          <a-row class="form_title">任务步骤</a-row>
          <a-form-item label="任务标题：">
            <a-input v-decorator="[
              'content.task.title',
              {
                rules: [{ required: true, message: '请输入任务标题!' }],
              },
            ]" />
          </a-form-item>
          <a-row>
            <a-col class="task" v-for="(item, index) in task" :key="index">
              序号 {{index+1}}
              <a-row class="task_all">
                <a-row class="task_con">
                  <a-row>
                    <a-form-item label="步骤标题">
                      <a-input v-decorator="[
                        'content.task.step[' + index + '].step_title',
                        {
                          rules: [{ required: true, message: '请输入步骤标题!' }],
                        },
                      ]" />
                    </a-form-item>
                    <a-form-item label="步骤描述" style="margin-left:10px">
                      <a-textarea v-decorator="[
                        'content.task.step[' + index + '].step_description',
                        {
                          rules: [{ required: true, message: '请输入步骤描述!' }],
                        },
                      ]" />
                    </a-form-item>
                  </a-row>
                  <a-row>
                    <a-form-item label="步骤状态">
                      <a-select 
                      style="width:180px" v-decorator="[
                        'content.task.step[' + index + '].step_status',
                        {
                          rules: [{ required: true, message: '请选择步骤状态!' }],
                        },
                      ]">
                        <a-select-option value="not_started">未开始</a-select-option>
                        <a-select-option value="in_progress">进行中</a-select-option>
                        <a-select-option value="under_review">审核中</a-select-option>
                        <a-select-option value="approved">审核通过</a-select-option>
                        <a-select-option value="rejected">审核不通过</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item style="margin-left:20px">
                      <a-checkbox-group
                    v-decorator="['content.task.step[' + index + '].is_resources',, { rules: [{ required: false }] }]"><a-checkbox
                      :value="1">提交单行文本</a-checkbox></a-checkbox-group>
                    </a-form-item>
                    <a-form-item style="margin-left:20px">
                      <a-checkbox-group
                    v-decorator="['content.task.step[' + index + '].is_screenshot',, { rules: [{ required: false }] }]"><a-checkbox
                      :value="1">提交单截图</a-checkbox></a-checkbox-group>
                    </a-form-item>
                  </a-row></a-row>
                <a-icon type="minus-circle" style="width:30px;padding-left: 10px;margin-top:-20px;  font-size: 20px"
                  v-if="(index == task.length - 1) && (index != 0)" @click="changeControl('delete', 'task', index)" />
                <a-icon type="plus-circle" style="width:30px;padding-left: 10px;margin-top:-20px; font-size: 20px"
                  v-if="(index == task.length - 1)" @click="changeControl('add', 'task', index)" /></a-row>
            </a-col>
          </a-row>
        </a-form>

      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      color: "",
      color1: "",
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "任务名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "顶部看板标题",
          dataIndex: "content.top_board.title",
          align: "center",
        },
        {
          title: "任务标题",
          dataIndex: "content.task.title",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      taskList: [],
      canUpload: "",
      gid: 49,
      top_board: [{}],
      task: [{}]
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },

    changeControl(operate, type) {
      if (operate == 'add') {
        if (type == 'top_board') {
          this.top_board.push({})
        }
        if (type == 'task') {
          this.task.push({})
        }
      }
      if (operate == 'delete') {
        if (type == 'top_board') {
          this.top_board.pop()
        }
        if (type == 'task') {
          this.task.pop()
        }
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/activityTaskTemplate/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var taskList = res.data.data;
            for (var i = 0; i < taskList.length; i++) {
              taskList[i].btns = ["编辑", "删除"];
            }
            that.changeTableH();
            that.taskList = taskList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //新增任务
    handleAdd() {
      this.visible = true;
      this.modelTitle = "新增任务";
      this.confirmLoading = false;
      this.id = "";
      var that = this;
      that.$nextTick(() => {
        that.addform.resetFields();
      });
    },
    //新增、编辑提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          for(var i=0;i<values.content.task.step.length;i++){
            values.content.task.step[i].is_resources=values.content.task.step[i].is_resources?1:0
            values.content.task.step[i].is_screenshot=values.content.task.step[i].is_screenshot?1:0
            values.content.task.step[i].step_number=i+1
          }
          var data = values;
          if (!that.id) {
            that.axios
              .post("/admin/activityTaskTemplate/create", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          } else {
            data.template_id = that.id;
            that.axios
              .post("/admin/activityTaskTemplate/update", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },

    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
      //   var data;
      that.id = num.template_id;
      if (btn == "编辑") {
        that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
        that.axios
          .get("/admin/activityTaskTemplate/detail",{params:{template_id:num.template_id}})
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.uploadBtn = false;
              that.visible = true;
              that.modelTitle = "编辑任务";
              for(var i=0;i<res.data.data.content.task.step.length;i++){
                res.data.data.content.task.step[i].is_screenshot=res.data.data.content.task.step[i].is_screenshot==1?[1]:''
                res.data.data.content.task.step[i].is_resources=res.data.data.content.task.step[i].is_resources==1?[1]:''
                delete res.data.data.content.task.step[i].resources_url
                delete res.data.data.content.task.step[i].screenshot_urls
                delete res.data.data.content.task.step[i].step_number
              }
              that.task=res.data.data.content.task.step
              that.top_board=res.data.data.content.top_board.content
              that.$nextTick(() => {
                that.addform.setFieldsValue({
                  name: res.data.data.name,
                  content: res.data.data.content,
                });
              });
            }
          });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此任务",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
            // data = {
            //   id: num.id,
            // };
            that.axios
              .post("/admin/activityTaskTemplate/delete", {template_id:num.template_id})
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}

.ant-table-wrapper {
  flex: 1;
}

.ant-modal-body form {
  overflow: hidden;
}

.ant-input-number {
  width: 100%;
}

.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}

/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}

/deep/.m-colorPicker .box {
  right: 0;
}

.top_board,
.task {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.task {
  flex-direction: column;
  align-items: flex-start;
}

.task .task_all {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.task .task_con{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right:20px
}
.task .task_con>.ant-row{
  flex:1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top_board .ant-form-item,
.task .ant-form-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.form_title {
  font-weight: 500;
  color: #333
}
</style>